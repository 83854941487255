<template>
    <div class="page signin-page">
        <van-nav-bar :border="false" :fixed="true" :left-text="$t('签到')" left-arrow @click-left="onClickLeft">
        </van-nav-bar>
        <div class="page-banner">
            <img src="../../assets/pictures/signin/banner.jpg" alt="">
        </div>
        <div class="wrapper">
            <div class="sign-main">
                <div class="sign-panel">
                    <ul class="sign-list">
                        <li v-for="(item, index) in signlist" :key="index">
                            <div class="sign-box" :class="{ 'signA': item.status == 1,'signA': item.status == 0 && current_date > item.date, 'current': item.date == current_date }">
                                <div class="label">{{$t('DAY'+item.day)}}</div>
                                <template v-if="item.status == 0 && current_date > item.date">
                                    <div class="icon">
                                        <img :src="gout3" alt="">
                                    </div>
                                    <div class="label">{{$t('漏签')}}</div>
                                </template>
                                <template v-else>
                                    <div class="icon">
                                        <img :src="item.status == 1? coin2 : coin " alt="">
                                    </div>
                                    <div class="label" v-if="item.status == 1">{{$t('已签')}}</div>
                                    <div class="label" v-else>{{item.money}}{{$t('USDT')}}</div>
                                </template>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="sign-foot">
                    <van-button type="primary" size="large" @click="saveSign">{{$t('打卡')}}</van-button>
                </div>
                <div class="tips-cell">
                    <div class="tip-label">{{$t('七日打卡规则')}}:</div>
                    <div class="tip-text" v-if="this.$t('sign_tip_1') != 'sign_tip_1'">
                        {{$t('sign_tip_1')}}
                    </div>
                    <div class="tip-text" v-if="this.$t('sign_tip_2') != 'sign_tip_2'">
                        {{$t('sign_tip_2')}}
                    </div>
                    <div class="tip-text" v-if="this.$t('sign_tip_3') != 'sign_tip_3'">
                        {{$t('sign_tip_3')}}
                    </div>
                    <div class="tip-text" v-if="this.$t('sign_tip_4') != 'sign_tip_4'">
                        {{$t('sign_tip_4')}}
                    </div>
                    <div class="tip-text" v-if="this.$t('sign_tip_5') != 'sign_tip_5'">
                        {{$t('sign_tip_5')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Toast } from 'vant';
    export default {
        components: {},
        data() {
            return {
                signlist: null,
                current_date:'',
                islast:false,
                coin:require('../../assets/pictures/signin/coin.png'),
                coin2:require('../../assets/pictures/signin/coin-sign.png'),
                gout:require('../../assets/pictures/signin/gout.png'),
                gout2:require('../../assets/pictures/signin/warning.png'),
                gout3:require('../../assets/pictures/signin/danger.png'),
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get(this.$api_url.get_signin_info)
                    .then((res) => {
                        if(res.data.code == 0){
                            this.signlist = res.data.data.info;
                            this.current_date = res.data.data.current_date;
                            console.log(this.signlist)
                        }
                    });
            },
            saveSign(){
                this.$toast.loading();
                this.$axios.post(this.$api_url.save_signin)
                .then((res) => {
                    console.log(res.data.msg)
                    if(res.data.code == 0){
                        Toast({
                            className:'toast-css',
                            type:'html',
                            message: '<h4>'+this.$t('今日打卡成功')+'</h4>' + '<p>'+this.$t('别忘了明日过来打卡哦')+'</p>',
                            icon: this.gout,
                        });
                        this.getData();
                    }
                    else if(res.data.code == -1){
                        Toast({
                            className:'toast-css',
                            type:'html',
                            message: this.$t(res.data.msg),
                            icon: this.gout2,
                        });
                    }
                }).catch((e) => {
                    this.$toast.fail(e.data.msg);
                })
            }
        },
		mounted() {
			this.getData();
		}

    }
</script>
<style scoped>
    .sign-panel{
        padding: 10px;
        background: url(../../assets/pictures/signin/bg.png) no-repeat;
        background-size: cover;
        border-radius: 12px;
        font-size: 14px;
    }
    .sign-foot{
        padding: 20px 15%;
    }
    .sign-list{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -3px;
    }
    .sign-list li{
        width: 25%;
        padding: 3px;
    }
    .sign-box{
        border-radius: 10px;
        padding: 10px 5px;
        text-align: center;
        line-height: 1;
        background-color: #79acbf;
    }
    .sign-box .label{
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        height: 20px;
    }
    .sign-box.signA{
        background-color: #131d26;
    }
    .sign-box.current{
        background-color: #ff5331;
    }
    .sign-box .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 6px auto;
        width: 30px;
        height: 30px;
    }
    .sign-box .icon img{
        width: 26px;
    }
    .tips-cell{
        line-height: 1.67;
        font-size: 14px;
    }
    .tip-label{
        margin-bottom: 10px;
    }
    .tip-text{
        color: #a3b6bd;
    }
    .sign-main{
        margin-top: -50px;
    }
    .wrapper{
        position: relative;
        z-index: 22;
    }
    
</style>









